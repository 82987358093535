<template>
      <v-menu>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                color="#000"
                dark
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                Geschäftsfeld wechseln
              </v-btn>
            </template>
            <span
              >Um die Detailansicht zu eines unserer anderen Geschäftsfelder zu wechseln, klicken Sie hier</span
            >
          </v-tooltip>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in items"
            :key="index"
            class="hover-item"
          >
            <v-list-item-title @click="changeRoute(item.route)">{{
              item.title
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
</template>
<script>
export default {
  name: "Menue",
  data: () => ({
    items: [
      { title: "Sanitärplanung", route: "/sanitaer" },
      { title: "Heizungsplanung", route: "/heizung" },
      { title: "Klimatisierung", route: "/klimatisierung" },
      { title: "Elektroplanung", route: "/elektro" },
      { title: "Lüftungsplanung", route: "/lueftung" },
      { title: "Brandschutzgutachten", route: "/brandschutz" },
    ],
  }),

  methods: {
    changeRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style scoped>
.hover-item:hover {
  background-color: #f2f2f2;
}
</style>
