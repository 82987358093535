<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Brandschutz</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/brandthumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einem Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <ul>
                  <li>
                    Prüfung des Brandschutzes im Genehmigungsverfahren
                  </li>
                  <li>Erstellung von Brandschutzkonzepten inkl. Brandschutzpläne</li>
                  <li>Erstellung gutachterlicher Stellungnahmen zu brandschutztechnischen Fragestellungen</li>
                  <li>Bestandsermittlungen und Schwachstellenanalysen</li>
                  <li>Entrauchungskonzepte nach DIN 18232</li>
                  <li>Baubegleitender Brandschutz innerhalb der Bauphase</li>
                </ul>
                <br />
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine
                Anfrage über das <a @click="toKontakt()">Kontaktformular</a>.
                Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Was sind Brandschutzkonzepte?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Brandschutzkonzepte sind wichtige Dokumente, die eine Bewertung
                der Brandsicherheit eines Gebäudes oder einer Anlage liefern.
                Sie dienen dazu, potenzielle Brandrisiken zu identifizieren, den
                Status des vorhandenen Brandschutzsystems zu bewerten und
                Empfehlungen für Verbesserungen oder Maßnahmen zur
                Risikominimierung zu geben.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Wie werden Brandschutzkonzepte erstellt?</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Erstellung eines Brandschutzkonzepts erfordert eine
                gründliche Analyse verschiedener Faktoren: Zunächst werden die
                baulichen Gegebenheiten des Gebäudes untersucht, einschließlich
                der Gebäudestruktur, der Materialien, aus denen es besteht, und
                der Anordnung der Räume. Auch die Nutzung des Gebäudes, die
                Anzahl der Personen, die sich darin aufhalten, und potenzielle
                Gefahrenquellen werden berücksichtigt. <br><br>Ein weiterer wichtiger
                Aspekt bei der Erstellung eines Brandschutzkonzepts ist die
                Bewertung des vorhandenen Brandschutzsystems. Hierbei werden die
                Wirksamkeit von Brandschutzmaßnahmen wie Brandmeldeanlagen,
                Sprinkleranlagen, Feuerlöschern und Fluchtwegen überprüft. Es
                wird analysiert, ob diese Systeme den geltenden
                Sicherheitsstandards entsprechen und ob sie ordnungsgemäß
                gewartet und funktionstüchtig sind. <br><br>Zusätzlich zur Bewertung der
                baulichen Gegebenheiten und des Brandschutzsystems werden
                potenzielle Brandrisiken und deren Auswirkungen untersucht.
                Hierzu gehören mögliche Zündquellen, brennbare Materialien,
                elektrische Anlagen, Heizungs- und Lüftungssysteme sowie
                mögliche Brandlasten. Es werden auch Szenarien simuliert, um die
                Ausbreitung von Bränden und deren mögliche Auswirkungen auf das
                Gebäude und die Menschen darin zu bewerten. <br><br>Basierend auf den
                gesammelten Informationen und Analysen werden im
                Brandschutzkonzept Empfehlungen zur Verbesserung der
                Brandsicherheit gegeben. Dies können Maßnahmen wie die
                Installation zusätzlicher Brandschutzsysteme, die Verbesserung
                der Fluchtwegsituation, die Schulung des Personals in
                Brandschutzverfahren oder die Anpassung der Nutzung des Gebäudes
                sein. <br><br>Insgesamt ist die Erstellung eines Brandschutzkonzepts
                ein gründlicher Prozess, der Fachwissen und Erfahrung erfordert.
                Es dient dazu, die Brandsicherheit eines Gebäudes zu bewerten,
                potenzielle Risiken zu identifizieren und geeignete Maßnahmen
                zur Risikominderung vorzuschlagen, um die Sicherheit von
                Menschen und Sachwerten zu gewährleisten.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white;" color="#000">Zurück zum Hauptmenü</v-btn>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/")
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  }
}</script>
