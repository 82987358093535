import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainWrap mt-0 pt-5"},[_c(VLayout,{staticClass:"ma-10"},[_c(VFlex,[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"align":"center"}},[_c(VImg,{staticClass:"ma-10",attrs:{"max-width":"25%","src":require("../assets/logoFarbe.svg")},on:{"click":function($event){return _vm.toHome()}}})],1)],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VSelect,{attrs:{"items":_vm.items,"label":"Anrede"},model:{value:(_vm.anrede),callback:function ($$v) {_vm.anrede=$$v},expression:"anrede"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"label":"Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"label":"E-Mail"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextField,{attrs:{"label":"Telefon"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VTextarea,{attrs:{"label":"Nachricht"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c(VRow,{attrs:{"no-gutters":""}},[_c(VCheckbox,{attrs:{"label":"Ich erkläre mich damit einverstanden, dass meine personenbezogenen Daten im Sinne der EU-Datenschutzgrundverordnung zur Bearbeitung und Beantwortung meiner Anfrage erhoben, übermittelt, verarbeitet und genutzt werden."},model:{value:(_vm.terms),callback:function ($$v) {_vm.terms=$$v},expression:"terms"}})],1),_c(VRow,{staticClass:"mb-8",attrs:{"no-gutters":""}},[_c(VBtn,{attrs:{"disabled":!_vm.terms},on:{"click":_vm.sendMail}},[_vm._v("Abschicken")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }