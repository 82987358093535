import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ContactView from '../views/ContactView.vue'
import ImprintView from '../views/ImprintView.vue'
import LocationView from '../views/LocationView.vue'
import AboutUsView from '../views/AboutUsView.vue'
import SanitaerView from '../views/SanitaerView.vue'
import HeizungView from '../views/HeizungView.vue'
import ElektroView from '../views/ElektroView.vue'
import LueftungsView from '../views/LueftungsView.vue'
import BrandschutzView from '../views/BrandschutzView.vue'
import KlimaView from '../views/KlimaView.vue'
import DatenschutzView from '../views/DatenschutzView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: ContactView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: ImprintView
  },
  {
    path: '/anfahrt',
    name: 'anfahrt',
    component: LocationView
  },
  {
    path: '/ueberuns',
    name: 'ueberuns',
    component: AboutUsView
  },
  {
    path: '/sanitaer',
    name: 'sanitaer',
    component: SanitaerView
  },
  {
    path: '/heizung',
    name: 'heizung',
    component: HeizungView
  },
  {
    path: '/elektro',
    name: 'elektro',
    component: ElektroView
  },
  {
    path: '/lueftung',
    name: 'lueftung',
    component: LueftungsView
  },
  {
    path: '/brandschutz',
    name: 'brandschutz',
    component: BrandschutzView
  },
  {
    path: '/klimatisierung',
    name: 'klimatisierung',
    component: KlimaView
  },
  {
    path: '/datenschutz',
    name: 'datenschutz',
    component: DatenschutzView
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
