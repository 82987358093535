<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text" style="font-size:15px"
                  >Vertretungsberechtigte Geschäftsführer</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>Lukas Bachmann</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text" style="font-size:15px">Anfahrt</v-card-title>
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>Boschstr. 26</span>
                <br />
                <span>71404 Korb</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text" style="font-size:15px">Kontakt</v-card-title>
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>Telefon: 07151-986686-0</span>
                <br />
                <span>Telefax: 07151-986686-5</span>
                <br />
                <span>E-Mail: info@ibg-online.de</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text" style="font-size:15px">Registereintrag</v-card-title>
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>Eintragung im Partnerschaftsregister</span>
                <br />
                <span>Registergericht: Stuttgart</span>
                <br />
                <span>Registernummer: PR 720635</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text" style="font-size:15px"
                  >Umsatzsteuer-Identifikationsnummer</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>Gemäß §27a Umsatzsteuergesetz: DE 231506858</span>
                <br />
                <span
                  >Inhaltlich Verantwortliche gemäß §6 MDStV: Lukas
                  Bachmann</span
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="px-1 pb-0">
            <span
              >Die Inhalte unserer Seiten entsprechen dem Kenntnisstand zum
              Zeitraum der Veröffentlichung. Änderungen vorbehalten. Trotz
              sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung
              für die Inhalte externer Links. Für den Inhalt der verlinkten
              Seiten sind ausschließlich deren Betreiber verantwortlich.</span
            >
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/")
    }
  }
}
</script>
