<template>
    <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
            <v-select :items="items" label="Anrede" v-model="anrede"></v-select>
        </v-row>
        <v-row no-gutters>
            <v-text-field label="Name" v-model="name"></v-text-field>
        </v-row>
        <v-row no-gutters>
            <v-text-field label="E-Mail" v-model="email"></v-text-field>
        </v-row>
        <v-row no-gutters>
            <v-text-field label="Telefon" v-model="tel"></v-text-field>
        </v-row>
        <v-row no-gutters>
            <v-textarea label="Nachricht" v-model="message"></v-textarea>
        </v-row>
        <v-row no-gutters>
            <v-checkbox v-model="terms" label="Ich erkläre mich damit einverstanden, dass meine personenbezogenen Daten im Sinne der EU-Datenschutzgrundverordnung zur Bearbeitung und Beantwortung meiner Anfrage erhoben, übermittelt, verarbeitet und genutzt werden."></v-checkbox>
        </v-row>
        <v-row no-gutters class="mb-8">
            <v-btn :disabled="!terms" @click="sendMail">Abschicken</v-btn>
        </v-row>
      </v-flex>
      </v-layout>
      </div>
</template>
<script>
export default {
    data() {
        return {
            anrede: null,
            items: ['Frau', 'Herr', 'Firma'],
            name: null,
            email: null,
            tel: null,
            message: null,
            terms: false
        }
    },
    methods: {
        sendMail() {
            var link = "mailto:info@ibg-online.de" //TODO: Mail ändern
            + "?subject=Kontaktformular " + this.name
            + "&body=" + "Anrede: " + this.anrede + "%0D%0AName: " + this.name + "%0D%0AE-Mail: " + this.email + "%0D%0ATelefon: " + this.tel + "%0D%0A%0D%0ANachricht: " + this.message
            window.location.href = link
        },
        toHome() {
      this.$router.push("/")
    }
    }
}
</script>
<style scoped>
.abc {
    background-color: red;
}
</style>