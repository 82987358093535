<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Klimatisierung</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/klimathumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einem Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Planung von: <br>
                <ul>
                  <li>
                    Teil- und Vollklimaanlagen
                  </li>
                  <li>
                    Wasser- und Kältemittelgeführte Anlagen
                  </li>
                  <li>
                    Flächenkühlung und Betonkernaktivierung
                  </li>
                </ul>
                <br>
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine Anfrage über das <a @click="toKontakt()">Kontaktformular</a>. Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
          </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Klimatisierung - Klimaanlagen</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Klimaanlagen sind Systeme, die in Gebäuden installiert werden,
                um die Temperatur und Luftfeuchtigkeit zu kontrollieren. Sie
                arbeiten durch das Entfernen von Wärme und Feuchtigkeit aus der
                Luft und sorgen so für eine angenehme Raumtemperatur.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Effiziente Klimatisierung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Klimaanlagen können in verschiedenen Gebäuden wie Wohnhäusern,
                Büros, Einkaufszentren und Hotels eingesetzt werden. Die beiden
                Systeme wären das Split- Systeme (mit separaten Innen- und
                Außeneinheiten) oder als zentrale Klimaanlagen (die das gesamte
                Gebäude kühlen). Das Ziel der Klimatisierung ist es, den Komfort
                der Menschen im Gebäude zu verbessern und eine angenehme
                Umgebung zu schaffen. Es ist jedoch wichtig zu beachten, dass
                die Klimatisierung auch Energie verbraucht und Auswirkungen auf
                die Umwelt haben kann. Daher ist es ratsam, energieeffiziente
                Systeme zu verwenden und bewusst mit der Klimatisierung
                umzugehen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white;" color="#000">Zurück zum Hauptmenü</v-btn>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/")
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  }
}</script>
