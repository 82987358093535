<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout>
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10 logoAnim"
              max-width="25%"
              src="../assets/logoFarbe.svg"
            />
          </v-col>
        </v-row>
        <v-row class="mx-5 mb-3">
          <v-col class="">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Geschäftsfelder im Überblick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Willkommen auf unserer Website! <br />
                Wenn Sie mehr Informationen zu eines unserer Geschäftsfelder
                einsehen möchten, klicken Sie auf das jeweilige Symbol, um zur
                Detailansicht zu gelangen. <br />
                <br />
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine
                Anfrage über das
                <a @click="changeRoute('/kontakt')">Kontaktformular</a>. Wir
                werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="cardAnim">
          <v-row class="mx-5">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/sanitaer')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-water-outline</v-icon
                    >
                    <span class="span-headline">Sanitärplanung</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/heizung')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-heat-wave</v-icon
                    >
                    <span class="span-headline">Heizungsplanung</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/klimatisierung')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-snowflake</v-icon
                    >
                    <span class="span-headline">Klimatisierung</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-5 mb-10 pb-2">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/elektro')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-flash-outline</v-icon
                    >
                    <span class="span-headline">Elektroplanung</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/lueftung')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-window-closed-variant</v-icon
                    >
                    <span class="span-headline">Lüftungsplanung</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 4 : 12">
              <v-card min-height="250px" class="centered-card custom-card" @click="changeRoute('/brandschutz')">
                <v-row>
                  <v-col class="centered-content" cols="12">
                    <v-icon color="#000" size="100"
                      >mdi-fire</v-icon
                    >
                    <span class="span-headline">Brandschutz</span>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import FlipCard from "../components/FlipCard.vue";
import anime from "../js/anime.min.js";

window.onload = function () {
  anime({
    targets: ".cardAnim .el",
    translateY: [500, 0],
    opacity: [0, 1],
    duration: 2000,
    delay: function (el, i) {
      return i * 200;
    },
  });
  anime({
    targets: ".logoAnim",
    opacity: [0, 1],
    duration: 7000,
  });
};
export default {
  name: "Home",
  components: {
    FlipCard,
  },
  data() {
    return {
      information: false,
      modal: false,
      modalContent: "",
      sanitaer: [
        { title: "allgemeine Sanitär- und Gasinstallation", value: 1 },
        { title: "Wasseraufbereitungsanlagen", value: 2 },
        { title: "Abwasser-Aufbereitungsanlagen", value: 3 },
        { title: "Küchentechnik", value: 4 },
        { title: "Druckluftanlagen", value: 5 },
        { title: "Schwimmbadtechnik", value: 6 },
        { title: "zentrale Staubsaugeranlagen", value: 7 },
        { title: "Regenwassernutzungsanlagen", value: 8 },
      ],
      items: [
        {
          title: "Item #1",
          value: 1,
        },
        {
          title: "Item #2",
          value: 2,
        },
        {
          title: "Item #3",
          value: 3,
        },
      ],
    };
  },
  methods: {
    showInfo() {
      console.log("information");
    },
    showModal(val) {
      this.modalContent = val;
      this.modal = true;
    },
    animateCard() {
      addEventListener("click", (cardAnim) => {
        anime({
          targets: ".cardAnim",
          translateY: -500,
          duration: 1500,
        });
      });
    },
    changeRoute(route) {
      this.$router.push(route);
    },
  },
};
</script>
<style>
.container .row .col {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainWrap {
  background-image: url("../assets/close-up-man-with-helmet-outdoors.jpg");
  background-size: cover;
}

.centered-card {
  display: flex;
  justify-content: center; /* Horizontal centering */
  align-items: center; /* Vertical centering */
  /* Additional card styles if needed */
}

.centered-content {
  display: flex;
  flex-direction: column; /* Vertically align children */
  align-items: center; /* Center children horizontally */
}

.span-headline {
  font-weight: bold;
  font-size: 20px;
}

.custom-card:hover {
  background-color: #f0f0f0; /* Change the background color on hover */
}
</style>
