<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col cols="12" style="display: flex; justify-content: center">
            <h1>Elektroplanung</h1>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1" cols="12">
            <v-carousel cycle class="ma-0">
              <v-carousel-item
                src="../assets/elektrothumb.png"
              />
            </v-carousel>
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Unsere Leistungen auf einem Blick</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Planung von: <br />
                <ul>
                  <li>
                    Nieder- und Mittelspannungsanalgen inkl. Trafostationen
                  </li>
                  <li>Sicherheitsstromversorgungen</li>
                  <li>PV-Anlagen</li>
                  <li>Beleuchtungskonzepten</li>
                  <li>Gebäudeautomationen</li>
                  <li>Blitzschutzanlagen</li>
                  <li>Kommunikationstechnik</li>
                  <li>Gefahren- und Einbruchmeldeanlagen</li>
                </ul>
                <br />
                Ihr Anliegen ist nicht dabei? Stellen Sie uns hierzu gern eine
                Anfrage über das <a @click="toKontakt()">Kontaktformular</a>.
                Wir werden uns mit Ihnen zeitnah in Verbindung setzen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Elektroplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Elektroplanung ist ein wesentlicher Bestandteil bei der
                Gestaltung und Konstruktion von Gebäuden und Anlagen. Sie
                umfasst die Planung, Installation und Wartung elektrischer
                Systeme, um eine zuverlässige und effiziente Stromversorgung
                sicherzustellen.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Anforderungen, Verteilung und Sicherheit</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Bei der Elektroplanung werden verschiedene Aspekte
                berücksichtigt, darunter die Anforderungen des Gebäudes oder der
                Anlage, die elektrische Last, die Sicherheitsstandards, die
                Energieeffizienz sowie die Integration von erneuerbaren
                Energiequellen. <br /><br />Zunächst werden die elektrischen
                Anforderungen ermittelt, wie die benötigte Leistung, die Anzahl
                der Steckdosen und Schalter, die Beleuchtung und die
                Kommunikationssysteme. <br />
                <br />Anschließend erfolgt die Planung der elektrischen
                Verteilung, einschließlich der Auswahl und Platzierung von
                Schalttafeln, Sicherungen, Schaltern und Verteilerkästen. Dabei
                wird die richtige Dimensionierung der elektrischen Leitungen und
                Kabel berücksichtigt, um eine Überlastung zu vermeiden.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Weitere wichtige Aspekte der Elektroplanung</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Die Elektroplanung umfasst auch die Planung der
                Beleuchtungssysteme, bei der die Anforderungen an die
                Helligkeit, die Lichtfarbe und die Steuerungssysteme
                berücksichtigt werden. Es werden effiziente Beleuchtungslösungen
                ausgewählt, um Energie zu sparen und die Umweltbelastung zu
                minimieren. <br />
                <br />Ein weiterer wichtiger Aspekt der Elektroplanung ist die
                Integration von erneuerbaren Energiequellen wie Solar- oder
                Windenergie. Die Planung und Installation von
                Photovoltaikanlagen oder Windturbinen ermöglicht die Nutzung
                sauberer Energie und reduziert den Bedarf an herkömmlicher
                Stromversorgung. <br />
                <br />Schließlich umfasst die Elektroplanung auch die
                Berücksichtigung von Sicherheitsstandards und die Installation
                von Brandschutzmaßnahmen wie Rauchmeldern und
                Notbeleuchtungssystemen. <br />
                <br />Insgesamt ist die Elektroplanung ein komplexer Prozess,
                der Fachwissen, Erfahrung und Sorgfalt erfordert. Eine
                sorgfältige Planung gewährleistet eine zuverlässige und sichere
                Stromversorgung für Gebäude und Anlagen und trägt zur
                Energieeffizienz und Nachhaltigkeit bei.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4 mt-5 d-flex justify-center">
          <v-btn @click="toHome()" style="color: white" color="#000"
            >Zurück zum Hauptmenü</v-btn
          >
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toKontakt() {
      this.$router.push("/kontakt")
    }
  },
};
</script>
