import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VApp,[_c(VFooter,{attrs:{"bottom":"","fixed":"","color":"black"}},[_c(VRow,{attrs:{"justify":"center"}},[_vm._l((_vm.links),function(link){return _c(VBtn,{key:link.name,staticClass:"mt-7",attrs:{"color":"white","text":"","rounded":"","link":"","to":link.route}},[_vm._v(" "+_vm._s(link.name)+" ")])}),_c(VCol,{staticClass:"py-4 text-center white--text",attrs:{"color":"black","cols":"12"}},[_vm._v(" "+_vm._s(new Date().getFullYear())+" - © Ingenieurbüro Bachmann ")])],2)],1),_c(VMain,[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }