<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout>
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10 logoAnim"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome"
            />
          </v-col>
        </v-row>
        <!-- <v-row class="mb-10 d-flex align-center justify-center">
          <v-btn style="color:white;" color="#000" @click="createDownloadLink()">Referenzen als PDF herunterladen</v-btn>
        </v-row> -->
        <v-row class="mx-5">
          <v-col class="">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Willkommen beim Ingenieurbüro Bachmann!</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Wir sind ein Ingenieurbüro für technische Gebäudeausrüstung (TGA) und bieten eine kompetente Beratung und Planung aller Arten von haustechnischen Anlagen. <br> <br>
                Gerne stehen wir Ihnen für die Planungsleistungen der Technischen Gebäudeausrüstung folgender Gewerke zur Verfügung: Heizung, Lüftung, Klima, Sanitär, Elektro und Brandschutz. <br> <br>
                Wir beraten Sie nach dem neuesten Stand der Technik sowie den aktuellen energetischen Gesetzlichkeiten unter Berücksichtigung wirtschaftlicher und ökologischer Lösungen. Unsere Planungsleistungen umfassen die kompletten Leistungsphasen der HOAI. <br> <br>
                Wir würden uns freuen Sie im Hinblick zur Energiewende kompetent beraten zu dürfen. Kommen Sie gern mit Ihrem Anliegen auf uns zu. <br> <br>
                Ihr IBG-Team.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mx-5">
          <v-col class="py-5" align="center">
          <span style="font-weight: bold; font-size:22px;">Unser Team:</span>
        </v-col>
        </v-row>
        <v-row class="mx-5">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Werner Bachmann</v-card-title>
                <v-card-text style="text-align: center;">Geschäftsführer, HLS Ingenieur</v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center"">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Lukas Bachmann</v-card-title>
                <v-card-text style="text-align: center;">Geschäftsführer, HLS Ingenieur</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-5 d-flex align-center justify-center">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Lisa Wiedmann</v-card-title>
                <v-card-text style="text-align: center;">Sachverständige Brandschutz, HLS Ingenieur</v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Felix Höss</v-card-title>
                <v-card-text style="text-align: center;">HLS Ingenieur</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-5 d-flex align-center justify-center">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Christian Friedrich</v-card-title>
                <v-card-text style="text-align: center;">Elektro Ingenieur</v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Phillipp Noller</v-card-title>
                <v-card-text style="text-align: center;">Elektro Ingenieur</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="mx-5 d-flex align-center justify-center">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Anil Karakaya</v-card-title>
                <v-card-text style="text-align: center;">Technischer Zeichner</v-card-text>
              </v-card>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="center">
              <v-card class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-exclamation-thick</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Wir suchen dich!</v-card-title>
                <v-card-text style="text-align: center;">- Bewerbungsformular bald verfügbar -</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-7 mx-8" />
          <v-row class="mx-5 d-flex align-left">
            <v-col :cols="$vuetify.breakpoint.mdAndUp ? 6 : 12" align="left">
              <v-card @click="changeRoute()" class="d-flex align-center justify-center" style="flex-direction: column">
                <v-icon class="pt-3" color="#000" size="100">mdi-account-circle</v-icon>
                <v-card-title style="text-align: center;" class="pa-0">Martina Liebs</v-card-title>
                <v-card-text style="text-align: center;">Sekretariat</v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-divider class="my-7 mx-8" />
          <v-row class="mx-5">
          <v-col class="mb-3">
            <v-card class="mb-12" width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text taetigkeitenHeadline"
                  >Referenzen</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                Für einen Überblick über unsere Referenzen können Sie folgendes Dokument herunterladen: <a @click="createDownloadLink()">Referenzen.pdf</a>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
  export default {
    data() {
      return {
        mitarbeiter: [
          {name: "Werner Bachmann", funktion: "Geschäftsführer, HLS Ingenieur"},
          {name: "Lukas Bachmann", funktion: "Geschäftsführer, HLS Ingenieur"},
          {name: "Martina Liebs", funktion: "Sekretariat"},
          {name: "Vanessa Houidi", funktion: "Sekretariat"},
          {name: "Lisa Wiedmann", funktion: "Sachverständige Brandschutz, HLS Ingenieur"},
          {name: "Felix Höss", funktion: "HLS Ingenieur"},
          {name: "Phillipp Noller", funktion: "Elektro Ingenieur"},
          {name: "Christian Friedrich", funktion: "Elektro Ingenieur"},
          {name: "Anil Karakaya", funktion: "Technischer Zeichner"},
        ]
      }
    },
    methods: {
        changeRoute() {
            this.$router.push("/kontakt")
        },
        toHome() {
          this.$router.push("/")
        },
        createDownloadLink() {
  var fileUrl = process.env.BASE_URL + 'referenzen.pdf'
  var fileName = 'referenzen.pdf'; 

  var link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName;
  link.click();

  document.body.appendChild(link);
}
    }
}
</script>