<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-row class="mb-10">
          <v-col class="px-1 pb-0">
            <span
              >Datenschutzerklärung für die Webseite und gleichzeitig
              Information der Betroffenen gemäß Artikel 13 und Artikel 14 der
              Datenschutzgrundverordnung (DS-GVO):</span
            >
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="cardHeadline white--text" style="font-size: 15px"
                  >Angaben zur verantwortlichen Stelle</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span
                  ><span class="headlineCustom">Unternehmen:</span>
                  <br />Ingenieurbüro Bachmann <br/>Beratende Ingenieure für technische Gebäudeausrüstung PartGmbB</span
                >
                <br /><br />
                <span>
                  <span class="headlineCustom">Gesetzlicher Vertreter:</span>
                  <br />Werner Bachmann, Lukas Bachmann
                </span>
                <br /><br />
                <span>
                  <span class="headlineCustom">Adresse:</span> <br />Boschstr.
                  26 <br />
                  71404 Korb
                </span>
                <br />
                <br />
                <span class="headlineCustom"
                  >Kontaktdaten des Datenschutzbeauftragten:</span
                >
                <br />
                <a href="mailto:datenschutz@ibg-online.de"
                  >datenschutz@ibg-online.de</a
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text cardHeadline" style="font-size: 15px"
                  >Allgemeine Datenverarbeitungs-Informationen</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span
                  >Personenbezogene Daten werden nur erhoben, wenn Sie uns diese
                  von sich aus mitteilen. Darüber hinaus werden keine
                  personenbezogenen Daten erhoben. Eine über die Reichweite der
                  gesetzlichen Erlaubnistatbestände hinausgehende Verarbeitung
                  Ihrer personenbezogenen Daten erfolgt nur auf Grundlage Ihrer
                  ausdrücklichen Einwilligung.</span
                >
                <br /><br />
                <span class="headlineCustom"> Verarbeitungszweck: </span>
                <ul>
                  <li>Vertragsdurchführung</li>
                </ul>
                <br />
                <span>
                  <span class="headlineCustom">Kategorien von Empfängern:</span>
                  <ul>
                    <li>
                      Öffentliche Stellen bei Vorliegen vorrangiger
                      Rechtsvorschriften
                    </li>
                    <li>Externe Dienstleister oder sonstige Auftragnehmer</li>
                    <li>
                      Weitere externe Stellen soweit der Betroffene seine
                      Einwilligung erteilt hat oder eine Übermittlung aus
                      überwiegendem Interesse zulässig ist
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom">Drittlandtransfers:</span>
                  <ul>
                    <li>
                      Im Rahmen der Vertragsdurchführung können auch
                      Auftragsverarbeiter außerhalb der Europäischen Union zum
                      Einsatz kommen
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom"
                    >Dauer der Datenspeicherung:</span
                  >
                  <ul>
                    <li>
                      Die Dauer der Datenspeicherung richtet sich nach den
                      gesetzlichen Aufbewahrungspflichten und beträgt in der
                      Regel 10 Jahre
                    </li>
                  </ul>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text cardHeadline" style="font-size: 15px"
                  >Spezifische Angaben zur Webseite</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span class="headlineCustom"
                  >Erhebung von Zugriffsdaten und Logfiles:</span
                >
                <br />
                <span>
                  Unser Hosting-Dienstleister erhebt auf Grundlage seiner
                  berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f.
                  DSGVO Daten über jeden Zugriff auf den Server, auf dem sich
                  dieser Dienst befindet (sogenannte Serverlogfiles). Zu den
                  Zugriffsdaten können der Name der abgerufenen Webseite, Datei,
                  Datum und Uhrzeit des Abrufs, die übertragene Datenmenge, eine
                  Meldung über erfolgreichen Abruf, der Browsertyp nebst
                  Version, das Betriebssystem des Nutzers, die Referrer URL (die
                  zuvor besuchte Seite), die IP-Adresse und der anfragende
                  Provider gehören.</span
                >
                <br />
                <br />
                <span
                  >Der genaue Umfang der erhobenen Daten sowie deren
                  Aufbewahrungsdauer sind in den
                  <a href="https://www.hosteurope.de/AGB/Datenschutzerklaerung/"
                    >Datenschutzbestimmungen</a
                  >
                  unseres Hosting-Dienstleisters genannt.</span
                >
                <br /><br />
                <span>
                  <span class="headlineCustom"
                    >Verwendung von Google Maps: </span
                  ><br />
                  Diese Webseite verwendet die Google Maps API, um
                  Standort-Informationen visuell darzustellen. Bei der Nutzung
                  von Google Maps werden von Google (Google Inc., 1600
                  Amphitheatre Parkway, Mountain View, California, 94043) auch
                  Daten über die Nutzung der Maps-Funktionen durch Besucher der
                  Webseiten erhoben, verarbeitet und genutzt. Nähere
                  Informationen über die Datenverarbeitung durch Google können
                  Sie den Datenschutzhinweisen von Google entnehmen, die Sie
                  unter
                  <a href="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy</a
                  >
                  abrufen können.</span
                >
                <br />
                <br />
                <span>
                  <span class="headlineCustom"
                    >Verwendung von Google Web Fonts:</span
                  >
                  <br />
                  Auf diesen Internetseiten werden externe Schriften, Google
                  Fonts verwendet. Google Fonts ist ein Dienst von Google
                  (Google Inc., 1600 Amphitheatre Parkway, Mountain View,
                  California, 94043). Die Einbindung dieser Web Fonts erfolgt
                  durch einen Serveraufruf, in der Regel ein Server von Google
                  in den USA. Hierdurch wird an den Server übermittelt, welche
                  unserer Internetseiten Sie besucht haben. Auch wird die
                  IP-Adresse des Browsers des Endgerätes des Besuchers dieser
                  Internetseiten von Google gespeichert. Nähere Informationen
                  über die Datenverarbeitung durch Google können Sie den
                  Datenschutzhinweisen von Google entnehmen, die Sie unter
                  <a href="https://policies.google.com/privacy"
                    >https://policies.google.com/privacy</a
                  >
                  sowie unter
                  <a href="https://fonts.google.com/#AboutPlace:about"
                    >https://fonts.google.com/#AboutPlace:about</a
                  >
                  abrufen können.
                </span>
                <br />
                <br />
                <span
                  ><span class="headlineCustom">Cloudflare:</span> <br />
                  Unsere Website benutzt Cloudflare, ein CDN (Content Delivery
                  Network) der Cloudflare Inc. mit Sitz in den USA. <br /><br />
                  Alle Daten die zu, oder von dieser Website übertragen werden
                  (dazu zählt auch Ihre IP-Adresse), werden über das weltweite
                  Netzwerk von CloudFlare verarbeitet. Cloudflare verwendet
                  dabei sog. "Cookies", Textdateien, die auf Ihrem Computer
                  gespeichert werden und die eine Erhöhung der
                  Leistungsfähigkeit und Sicherheit der Website ermöglichen. Die
                  durch den Cookie erzeugten Informationen über Ihre Benutzung
                  dieser Website werden in- und außerhalb der Europäischen Union
                  zwischengespeichert und protokolliert. Nach Auskunft von
                  CloudFlare werden die zwischengespeicherten Daten
                  grundsätzlich innerhalb von 4 Stunden, spätestens jedoch nach
                  einer Woche, gelöscht. Weitere Informationen finden Sie in der
                  Datenschutzerklärung von CloudFlare unter:
                  <a href="https://www.cloudflare.com/security-policy"
                    >https://www.cloudflare.com/security-policy</a
                  >. Was Cloudflare tatsächlich speichert, wird außerdem auch
                  hier erläutert:
                  <a href="https://blog.cloudflare.com/what-cloudflare-logs/"
                    >https://blog.cloudflare.com/what-cloudflare-logs/</a
                  ><br /><br />
                  Durch die Nutzung unserer Website erklären Sie sich mit der
                  Bearbeitung der über Sie erhobenen Daten durch Cloudflare in
                  der zuvor beschriebenen Art und Weise und Zweck einverstanden.
                  <br /><br />
                  Wir stützen den Einsatz der vorgenannten Tools auf Art. 6 Abs.
                  1 lit. (f) DSGVO: Die Datenverarbeitung erfolgt zur
                  Sicherstellung des Betriebs sowie zur Verbesserung der
                  Nutzerfreundlichkeit auf unserer Webseite und liegt daher in
                  unserem berechtigten Interesse.
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text cardHeadline" style="font-size: 15px"
                  >Spezifische Angaben zum Bewerbungsverfahren</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>
                  <span class="headlineCustom">Betroffene Daten:</span>
                  <ul>
                    <li>Bewerbungsangaben</li>
                  </ul>
                </span>
                <br />
                <span>
                  <span class="headlineCustom">Verarbeitungszweck:</span>
                  <ul>
                    <li>Durchführung Bewerbungsverfahren</li>
                  </ul>
                  <br />
                </span>
                <span>
                  <span class="headlineCustom">Kategorien von Empfängern:</span>
                  <ul>
                    <li>
                      Öffentliche Stellen bei Vorliegen vorrangiger
                      Rechtsvorschriften
                    </li>
                    <li>Externe Dienstleister oder sonstige Auftragnehmer</li>
                    <li>
                      Weitere externe Stellen soweit der Betroffene seine
                      Einwilligung erteilt hat oder eine Übermittlung aus
                      überwiegendem Interesse zulässig ist
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom">Drittlandtransfers:</span>
                  <ul>
                    <li>
                      Im Rahmen der Vertragsdurchführung können auch
                      Auftragsverarbeiter außerhalb der Europäischen Union zum
                      Einsatz kommen
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom"
                    >Dauer der Datenspeicherung:</span
                  >
                  <ul>
                    <li>
                      Bewerbungsdaten werden nach Mitteilung der Entscheidung in
                      der Regel binnen vier Monaten gelöscht, soweit nicht eine
                      Einwilligung in eine längere Datenspeicherung vorliegt
                    </li>
                  </ul>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="white--text cardHeadline" style="font-size: 15px"
                  >Spezifische Angaben zur Verarbeitung von
                  Kundendaten/Interessentendaten</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>
                  <span class="headlineCustom">Betroffene Daten:</span>
                  <ul>
                    <li>
                      Zur Vertragsdurchführung mitgeteilte Daten; ggfs. darüber
                      hinaus gehende Daten zur Verarbeitung auf Basis Ihrer
                      ausdrücklichen Einwilligung.
                    </li>
                  </ul></span
                >
                <br />
                <span>
                  <span class="headlineCustom">Verarbeitungszweck:</span>
                  <ul>
                    <li>Vertragsdurchführung</li>
                  </ul>
                  <br />
                </span>
                <span>
                  <span class="headlineCustom">Kategorien von Empfängern:</span>
                  <ul>
                    <li>
                      Öffentliche Stellen bei Vorliegen vorrangiger
                      Rechtsvorschriften
                    </li>
                    <li>Externe Dienstleister oder sonstige Auftragnehmer</li>
                    <li>
                      Weitere externe Stellen soweit der Betroffene seine
                      Einwilligung erteilt hat oder eine Übermittlung aus
                      überwiegendem Interesse zulässig ist
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom">Drittlandtransfers:</span>
                  <ul>
                    <li>
                      Im Rahmen der Vertragsdurchführung können auch
                      Auftragsverarbeiter außerhalb der Europäischen Union zum
                      Einsatz kommen
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom"
                    >Dauer der Datenspeicherung:</span
                  >
                  <ul>
                    <li>
                      Die Dauer der Datenspeicherung richtet sich nach den
                      gesetzlichen Aufbewahrungspflichten und beträgt in der
                      Regel 10 Jahre
                    </li>
                  </ul>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="cardHeadline white--text" style="font-size: 15px"
                  >Spezifische Angaben zur Verarbeitung von
                  Beschäftigtendaten</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>
                  <span class="headlineCustom">Betroffene Daten:</span>
                  <ul>
                    <li>
                      Zur Vertragsdurchführung mitgeteilte Daten; ggfs. darüber
                      hinaus gehende Daten zur Verarbeitung auf Basis Ihrer
                      ausdrücklichen Einwilligung
                    </li>
                  </ul>
                </span>
                <br />
                <span>
                  <span class="headlineCustom">Verarbeitungszweck:</span>
                  <ul>
                    <li>Vertragsdurchführung</li>
                  </ul>
                  <br />
                </span>
                <span>
                  <span class="headlineCustom">Kategorien von Empfängern:</span>
                  <ul>
                    <li>
                      Öffentliche Stellen bei Vorliegen vorrangiger
                      Rechtsvorschriften
                    </li>
                    <li>Externe Dienstleister oder sonstige Auftragnehmer</li>
                    <li>
                      Weitere externe Stellen soweit der Betroffene seine
                      Einwilligung erteilt hat oder eine Übermittlung aus
                      überwiegendem Interesse zulässig ist
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom">Drittlandtransfers:</span>
                  <ul>
                    <li>
                      Im Rahmen der Vertragsdurchführung können auch
                      Auftragsverarbeiter außerhalb der Europäischen Union zum
                      Einsatz kommen
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom"
                    >Dauer der Datenspeicherung:</span
                  >
                  <ul>
                    <li>
                      Die Dauer der Datenspeicherung richtet sich nach den
                      gesetzlichen Aufbewahrungspflichten und beträgt in der
                      Regel 10 Jahre
                    </li>
                  </ul>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="cardHeadline white--text" style="font-size: 15px"
                  >Spezifische Angaben zur Verarbeitung von
                  Lieferantendaten</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span>
                  <span class="headlineCustom">Betroffene Daten:</span>
                  <ul>
                    <li>
                      Zur Vertragsdurchführung mitgeteilte Daten; ggfs. darüber
                      hinaus gehende Daten zur Verarbeitung auf Basis Ihrer
                      ausdrücklichen Einwilligung
                    </li>
                  </ul></span
                >
                <br />
                <span>
                  <span class="headlineCustom">Verarbeitungszweck:</span>
                  <ul>
                    <li>Vertragsdurchführung</li>
                  </ul>
                  <br />
                </span>
                <span>
                  <span class="headlineCustom">Kategorien von Empfängern:</span>
                  <ul>
                    <li>
                      Öffentliche Stellen bei Vorliegen vorrangiger
                      Rechtsvorschriften
                    </li>
                    <li>Externe Dienstleister oder sonstige Auftragnehmer</li>
                    <li>
                      Weitere externe Stellen soweit der Betroffene seine
                      Einwilligung erteilt hat oder eine Übermittlung aus
                      überwiegendem Interesse zulässig ist
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom">Drittlandtransfers:</span>
                  <ul>
                    <li>
                      Im Rahmen der Vertragsdurchführung können auch
                      Auftragsverarbeiter außerhalb der Europäischen Union zum
                      Einsatz kommen
                    </li>
                  </ul>
                </span>
                <br />
                <span
                  ><span class="headlineCustom"
                    >Dauer der Datenspeicherung:</span
                  >
                  <ul>
                    <li>
                      Die Dauer der Datenspeicherung richtet sich nach den
                      gesetzlichen Aufbewahrungspflichten und beträgt in der
                      Regel 10 Jahre
                    </li>
                  </ul>
                </span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-4">
          <v-col class="pa-1">
            <v-card width="100%">
              <v-system-bar color="black" class="py-6 white--text">
                <v-card-title class="cardHeadline white--text" style="font-size: 15px"
                  >Weitere Informationen und Kontakte</v-card-title
                >
              </v-system-bar>
              <v-card-text class="mx-2 black--text">
                <span
                  >Darüber hinaus können Sie jederzeit ihre Ansprüche auf
                  Berichtigung oder Löschung oder auf Einschränkung der
                  Verarbeitung oder der Wahrnehmung Ihres Widerspruchsrechts
                  gegen die Verarbeitung sowie das Recht auf
                  Datenübertragbarkeit geltend machen. Hier finden Sie die
                  Möglichkeit, uns per E-Mail, gerne über das
                  <a @click="toKontakt()">Kontaktformular</a> oder per Brief
                  (Anschrift siehe <a @click="toImpressum()">Impressum</a>) zu
                  kontaktieren. Sie haben ferner das Recht, sich bei Beschwerden
                  an die Datenschutz-Aufsichtsbehörde zu wenden.</span
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
  methods: {
    toHome() {
      this.$router.push("/");
    },
    toKontakt() {
        this.$router.push("/kontakt")
    },
    toImpressum() {
        this.$router.push("/impressum")
    }
  },
};
</script>
<style scoped>
.headlineCustom {
  font-weight: bold;
  font-size: 14px;
}
.cardHeadline {
    line-height: 20px;
}
</style>
