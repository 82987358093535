import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VMenu,{scopedSlots:_vm._u([{key:"activator",fn:function({ on: menu, attrs }){return [_c(VTooltip,{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on: tooltip }){return [_c(VBtn,_vm._g(_vm._b({attrs:{"color":"#000","dark":""}},'v-btn',attrs,false),{ ...tooltip, ...menu }),[_vm._v(" Geschäftsfeld wechseln ")])]}}],null,true)},[_c('span',[_vm._v("Um die Detailansicht zu eines unserer anderen Geschäftsfelder zu wechseln, klicken Sie hier")])])]}}])},[_c(VList,_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,staticClass:"hover-item"},[_c(VListItemTitle,{on:{"click":function($event){return _vm.changeRoute(item.route)}}},[_vm._v(_vm._s(item.title))])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }