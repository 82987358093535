<template>
  <div class="mainWrap mt-0 pt-5">
    <v-layout class="ma-10">
      <v-flex>
        <v-row no-gutters>
          <v-col align="center">
            <v-img
              class="ma-10"
              max-width="25%"
              src="../assets/logoFarbe.svg"
              @click="toHome()"
            />
          </v-col>
        </v-row>
        <v-layout row wrap align-center>
          <v-flex>

            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2626.233754201544!2d9.3662738!3d48.834679799999996!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799c86504113015%3A0x97b909864bba7836!2sBoschstra%C3%9Fe%2026%2C%2071404%20Korb!5e0!3m2!1sde!2sde!4v1677957322731!5m2!1sde!2sde"
              width="100%"
              height="400px"
              style="1uto: 0; border: none;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
              class="rounded-lg"
            ></iframe>
            <div class="mt-10 mb-10" style="text-align: center;">
            <span>
                Unser Standort ist 
                <span style="font-weight: bold;">Korb im Rems-Murr-Kreis, Region Stuttgart.</span>
            </span>
            <br><br>
            <span>
                Lieber Besucher, unseren Standort sehen Sie auf der eingeblendeten Karte.
            </span>
        </div>
        </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
export default {
methods: {
  toHome() {
      this.$router.push("/")
    }
}
}
</script>
